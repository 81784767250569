<template>
  <docs-layout> 
    <div class="space-y-4 pb-10 w-full">
      <asom-breadcrumb
        :links="linksA"
      />
      <asom-breadcrumb
        :links="linksB"
      />
      <asom-breadcrumb
        :links="linksC"
      />
    </div>
  </docs-layout>
</template>
<script>
import DocsLayout from '@/docs/DocsLayout.vue'
export default {
  components: {
    DocsLayout,
  },
  data() {
    return {
      linksA: [
        { text: 'Home', 'href': '#' },
      ],
      linksB: [
        { text: 'Home', 'href': '#' },
        { text: 'Library', 'href': '#' },
      ],
      linksC: [
        { text: 'Home', 'href': '#' },
        { text: 'Library', 'href': '#' },
        { text: 'Data', 'href': '#' },
      ]
    }
  }
}
</script>